<template>
    <div class="container">
        <div class="footer">
            <div class="top">
                <div class="wrapper">
                    <div class="left">
                        <div class="left_top">
                            <img src="../../assets/home/image 17.png" alt="" />
                        </div>
                        <div class="left_body">
                            <div style="opacity: 1 !important">北京莱普博乐科技有限公司</div>
                            <div class="enName-company">Beijing Labbol Technology Co. ，Ltd</div>
                            <div>
                                <img height="17" width="12" src="../../assets/home/Subtract.png" />
                                &nbsp;&nbsp;北京：北京市海淀区后屯路28号院国际技术转移中心三层
                            </div>
                            <!-- <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;武汉：武汉市东湖新技术开发区现代光谷世贸中心E栋8层</div> -->
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;武汉：武汉市关东工业园佳园路9号同亨大厦7层</div>
                            <div style="margin-top: 17px">
                                <!-- <img height="10" width="14" src="../../assets/home/Vector.png" /> -->
                               
                                &nbsp;&nbsp;电话 / Te1：400-0707-820
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <div class="center_top">
                            产品 <span>&</span> 解决方案
                            <!-- <div class="tag"></div> -->
                        </div>
                        <div class="center_body">
                            <div @click="$router.push('/product')">报告自动化系统</div>
                            <div  @click="$router.push('/dcc')">数字校准证书系统</div>
                            <div @click="goCooaskPage">双碳情报系统</div>
                            <div  @click="$router.push('/smartLab')">智慧实验室</div>
                            <div>智能检测边缘计算网关</div>
                        </div>
                    </div>
                    <div class="center01">
                        <div class="center_top">
                            关于我们
                            <!-- <div class="tag"></div> -->
                        </div>
                        <div class="center_body">
                            <div  @click="scrollToSection('profile')">公司简介</div>
                            <div @click="scrollToSection('partners-section')">合作伙伴</div>
                            <div @click="scrollToSection('qualifications-section')">公司资质</div>
                            <div @click="scrollToSection('about-us')">联系我们</div>
                            <!-- <div>联系我们</div> -->
                        </div>
                    </div>
                    <div class="right">
                        <div class="right_box">
                            <div class="right_top">
                                <img src="../../assets/gongsi/erweima.png" alt="" />
                                <img src="../../assets/gongsi/erweima2.png" alt="" />
                            </div>
                            <div class="right_body">
                                <span>关注我们</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="wrapper">
                    <div style="width: 258px; white-space: nowrap">
                        <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">Copyright @2022 Labbol All Rights Reserved</p>
                    </div>
                    <div style="width: 180px; white-space: nowrap">
                        <a target="_blank" href="https://beian.miit.gov.cn" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px">
                            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">备案号：京ICP备2022014052号-1</p>
                        </a>
                    </div>
                    <div style="width: 204px; white-space: nowrap">
                        <a
                            target="_blank"
                            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040825"
                            style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
                        >
                            <img src="../../assets/gongsi/备案图标.png" style="float: left" />
                            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">京公网安备 11010802040825号</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "iFooter",
    data() {
        return {}
    },
    methods: {
        contactUs() {
            this.$router.push({ name: "about", params: { selection: "about-us" }, query: { index: 4 } })
        },
        goCooaskPage()
        {
            window.open('http://cooask.com','_blank')
        },
        //关于我们跳转
        scrollToSection(section) {
            this.$router.push({ name: 'about', hash: `#${section}` } );
        }
    },
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    // height: 360px;
    background-color: #0e1421;
    color: #fff;
    display: flex;
    justify-content: center;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    .footer {
        width: 100%;
        .top {
            display: flex;
            justify-content: center;
            height: 340px;
            > .wrapper {
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .left {
                    // margin-left:120px;
                    .left_top {
                        margin-top: 66px;
                        img {
                            width: 208px;
                            height: 24px;
                        }
                    }
                    .left_body {
                        opacity: 0.6;
                        width: 429px;
                        height: 112px;
                        line-height: 30px;
                        margin-top: 24px;
                        font-size: 14px;
                        > div {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                .center {
                    margin-left: 50px;
                    .center_top {
                        margin-top: 66px;
                        font-size: 16px;
                        font-weight: bold;
                        position: relative;
                        > .tag {
                            position: absolute;
                            width: 65px;
                            height: 2px;
                            top: 30px;
                            background: #ffffff;
                        }
                    }
                    .center_body {
                        width: 160px;
                        opacity: 0.6;
                        height: 140px;
                        line-height: 30px;
                        margin-top: 24px;
                        font-size: 14px;
                        cursor: pointer;
                        &>div{
                            &:hover{
                            color:#2d74f4 ;
                        }
                        }
                    }
                }
                .center01 {
                    margin-left: 60px;
                    .center_top {
                        margin-top: 66px;
                        font-size: 16px;
                        font-weight: bold;
                        position: relative;
                        > .tag {
                            position: absolute;
                            width: 65px;
                            height: 2px;
                            top: 30px;
                            background: #ffffff;
                        }
                    }
                    .center_body {
                        width: 160px;
                        height: 140px;
                        opacity: 0.6;
                        line-height: 30px;
                        margin-top: 24px;
                        font-size: 14px;
                        cursor: pointer;
                        &>div{
                            &:hover{
                            color:#2d74f4 ;
                        }
                    }


                    }
                }
                .right {
                    margin-left: 75px;
                    display: flex;
                    align-items: center;
                    .right_box {
                        margin-top: 5px;
                        &>.right_top{
                            display: flex;
                            flex-flow: row nowrap;
                            &>img{
                                width: auto;
                                height: 134px;
                                & + img{
                                    margin: 0 0 0 22px;
                                }
                            }
                        }
                        .right_body {
                            // width: 70px;
                            width: 134px;
                            margin-top: 17px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .bottom {
            width: 100%;
            height: 58px;
            font-size: 12px;
            color: #fff;
            // line-height: 60px;
            background: #0e1421;
            display: flex;
            border-top: 1px solid #2b313c;
            justify-content: center;
            align-items: center;
            > .wrapper {
                width: 710px;
                white-space: nowrap;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
